import {
  DEBRIEF_SCHEDULE_PENDING,
  GET_DEBRIEF_SCHEDULES,
  GET_DEBRIEF_SCHEDULES_UPCOMING,
  GET_DEBRIEF_SCHEDULES_SELECT_LIST,
  POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY,
  DELETE_DEBRIEF_SCHEDULE,
  DEBRIEF_SCHEDULE_PAGE_NUMBER,
  DEBRIEF_SCHEDULE_SEARCH,
  DEBRIEF_SCHEDULE_SEARCH_TERM,
  FETCH_DEBRIEF_SUMMARY,
  SELECTED_DEBRIEF_SCHEDULE,
  RE_SELECTED_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE_SUMMARY,
  EDIT_DEBRIEF_SCHEDULE,
  ADD_NEW_DEBRIEF_SCHEDULE,
  CLEAR_DEBRIEF_SCHEDULE,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  GENERATE_DEBRIEF_TOKEN,
  SEND_DEBRIEF_TOKEN_EMAIL,
  MERGE_DEBRIEF_SCHEDULE,
  POST_GOOGLE_DEMO_SHEET,
  CATEGORY_SUMMARY_DEBRIEF_RESPONSE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_schedule: [],
  select_list: [],
  category_summary: [],
  upcoming: [],
  debrief_id: null,
  ghseet_summary: null,
  excel_summary: null,
  pending: false,
  tokenGenerated: false,
  merge_debrief: false,
  search_term: false,
  emailSent: false,
  created: null,
  updated: null,
  deleted: false,
  search: false,
  summary: false,
  activePage: 1,
  pageNumber: 1,
};

export default function DebriefSchedule(state = initialState, action) {
  switch (action.type) {
    case DEBRIEF_SCHEDULE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_DEBRIEF_SCHEDULES_SELECT_LIST:
      return {
        ...state,
        pending: false,
        select_list: action.payload,
      };
    case GET_DEBRIEF_SCHEDULES:
      // console.log("Debrief reducer" ,action.payload)
      return {
        ...state,
        debrief_schedule: action.payload,
        pending: false,
        created: null,
        // debrief_id: null,
      };
    case GET_DEBRIEF_SCHEDULES_UPCOMING:
      return {
        ...state,
        upcoming: action.payload,
        pending: false,
        // debrief_id: null,
      };
    case POST_DEBRIEF_SCHEDULE_EXCEL_SUMMARY:
      return {
        ...state,
        excel_summary: action.payload,
        pending: false,
      };
    case DEBRIEF_SCHEDULE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
        pending: false,
      };
    case DEBRIEF_SCHEDULE_SEARCH_TERM:
      return {
        ...state,
        pending: false,
        search_term: action.payload,
      };
    case DEBRIEF_SCHEDULE_SEARCH:
      return {
        ...state,
        pending: false,
        search: action.payload,
      };
    case FETCH_DEBRIEF_SUMMARY:
      return {
        ...state,
        summary: action.payload,
        pending: false,
      };
    case FETCH_DEBRIEF_SCHEDULE_SUMMARY:
      return {
        ...state,
        summary: action.payload,
        pending: false,
      };
    case GENERATE_DEBRIEF_TOKEN:
      return {
        ...state,
        tokenGenerated: action.payload,
        pending: false,
      };
case CATEGORY_SUMMARY_DEBRIEF_RESPONSE:
      return {
        ...state,
        category_summary: action.payload,
        pending: false,
      };
    case MERGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        merge_debrief: action.payload,
        pending: false,
      };
    case SEND_DEBRIEF_TOKEN_EMAIL:
      return {
        ...state,
        emailSent: action.payload,
        pending: false,
      };
    case GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        activePage: action.payload,
        pending: false,
      };
    case ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        activePage: action.payload,
        pending: false,
      };
    case SELECTED_DEBRIEF_SCHEDULE:
      return {
        ...state,
        debrief_id: action.payload,
        pending: false,
      };
    case RE_SELECTED_DEBRIEF_SCHEDULE:
      return {
        ...state,
        debrief_id: null,
        pending: false,
      };
    case POST_GOOGLE_DEMO_SHEET:
      return {
        ...state,
        ghseet_summary: action.payload,
        pending: false,
      };
    case FETCH_DEBRIEF_SCHEDULE:
      // console.log(action.payload)
      return {
        ...state,
        [action.payload.id]: action.payload,
        debrief_id: action.payload.id,
        pending: false,
        created: null,
      };

    case EDIT_DEBRIEF_SCHEDULE:
      return {
        ...state,
        // debrief_schedule: [...state.debrief_schedule, action.payload],
        [action.payload.id]: action.payload,
        debrief_id: action.payload.id,
        updated: action.payload.id,
        pending: false,
      };

    case DELETE_DEBRIEF_SCHEDULE:
      const new_result = state.debrief_schedule.results.filter(
        (row) => row.id !== action.payload.id
      );
      return {
        ...state,
        debrief_schedule: {
          ...(state.debrief_schedule.results = new_result),
        },
        created: null,
        pending: false,
      };
    case RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        created: null,
      };
    case ADD_NEW_DEBRIEF_SCHEDULE:
      // console.log(action.payload)
      return {
        ...state,
        created: action.payload,
        pending: false,
        // [action.payload.id]: action.payload,
        // debrief_schedule: [...state.debrief_schedule, action.payload],
        // debrief_id: null,
      };

    case CLEAR_DEBRIEF_SCHEDULE:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
